import actionTypes from "_constants/actionTypes";
export const timeActions = {
  getLast,initTime,endTime,
  endTransaction,endDay, updateMood,getHoursDate,addHoursDate,delHoursDate
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function delHoursDate(id) {
  const payload = id;
  return {
    type: actionTypes.DEL_TIMEDAY,
    payload,
  };
}


function getHoursDate(date) {
  const payload = date;
  return {
    type: actionTypes.GET_HOURSDAY_USER,
    payload,
  };
}

function addHoursDate(body) {
  const payload = {body};
  return {
    type: "SAVE_H",
    payload,
  };
}


function updateMood(mood) {
  const payload = {mood};
  return {
    type: actionTypes.MOOD_DAY_USER,
    payload,
  };
}

function endDay() {
  const payload = "";
  return {
    type: actionTypes.END_DAY_USER,
    payload,
  };
}
function endTime(time) {
  const payload = {time};

  return {
    type: actionTypes.END_TIME_USER,
    payload,
  };
}

function getLast() {
  const payload = "";
  return {
    type: actionTypes.GET_LAST_USER,
    payload,
  };
}
function initTime() {
  const payload = "";
  return {
    type: actionTypes.INIT_TIME_USER,
    payload,
  };
}
