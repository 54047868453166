import { combineReducers } from "redux";
import loading from "./loading.reducer";
import authReducer from "./auth.reducer";
import userReducer from "./user.reducer";
import timeReducer from './time.reducer';
import permissionReducer from './permission.reducer';
const rootReducer = combineReducers({
  loading,
  authReducer,permissionReducer,
  userReducer,timeReducer
});

export default rootReducer;
