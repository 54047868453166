export default {
  API_DONE: "API_DONE",
  START_REQUEST: "START_REQUEST",
  END_REQUEST: "END_REQUEST",
  RESET_LOADING: "RESET_LOADING",
  INCREMENT_COUNTER: "INCREMENT_COUNTER",
  DECREMENT_COUNTER: "DECREMENT_COUNTER",
  INCREMENT_COUNTER_DOUBLE: "INCREMENT_COUNTER_DOUBLE",
  DECREMENT_COUNTER_DOUBLE: "DECREMENT_COUNTER_DOUBLE",
  END_TRANSACTION: "END_TRANSACTION",
  LOGOUT: "LOGOUT",
  LOGIN_USER: "LOGIN_USER",
  LOGIN_USER_DATA: "LOGIN_USER_DATA",
  LOGIN_USER_ERROR: "LOGIN_USER_ERROR",
  CHANGEPASS_USER: "CHANGEPASS_USER",
  CHANGEPASS_USER_DATA: "CHANGEPASS_USER_DATA",
  CHANGEPASS_USER_ERROR: "CHANGEPASS_USER_ERROR",
  RESET_USER: "RESET_USER",
  RESET_USER_DATA: "RESET_USER_DATA",
  RESET_USER_ERROR: "RESET_USER_ERROR",

  UPDATE_USER_AUTH: "UPDATE_USER_AUTH",
  UPDATE_USER_AUTH_DATA: "UPDATE_USER_AUTH_DATA",
  UPDATE_USER_AUTH_ERROR: "UPDATE_USER_AUTH_ERROR",

  UPDATE_PWD_AUTH: "UPDATE_PWD_AUTH",
  UPDATE_PWD_AUTH_DATA: "UPDATE_PWD_AUTH_DATA",
  UPDATE_PWD_AUTH_ERROR: "UPDATE_PWD_AUTH_ERROR",

  MEINIT: "MEINIT",
  MEINIT_DATA: "MEINIT_DATA",
  MEINIT_ERROR: "MEINIT_ERROR",

  MEDATA: "MEDATA",
  MEDATA_DATA: "MEDATA_DATA",
  MEDATA_ERROR: "MEDATA_ERROR",

  MEPASSWORD: "MEPASSWORD",
  MEPASSWORD_DATA: "MEPASSWORD_DATA",
  MEPASSWORD_ERROR: "MEPASSWORD_ERROR",

  MECLEAR: "MECLEAR",

  GET_MEDATA: "GET_MEDATA",
  GET_MEDATA_DATA: "GET_MEDATA_DATA",
  GET_MEDATA_ERROR: "GET_MEDATA_ERROR",

  GET_LOGROSDATA: "GET_LOGROSDATA",
  GET_LOGROSDATA_DATA: "GET_LOGROSDATA_DATA",
  GET_LOGROSDATA_ERROR: "GET_LOGROSDATA_ERROR",

  GET_PUNTOSDATA: "GET_PUNTOSDATA",
  GET_PUNTOSDATA_DATA: "GET_PUNTOSDATA_DATA",
  GET_PUNTOSDATA_ERROR: "GET_PUNTOSDATA_ERROR",

  GET_RANKINGDATA: "GET_RANKINGDATA",
  GET_RANKINGDATA_DATA: "GET_RANKINGDATA_DATA",
  GET_RANKINGDATA_ERROR: "GET_RANKINGDATA_ERROR",

  GET_NIVELES: "GET_NIVELES",
  GET_NIVELES_DATA: "GET_NIVELES_DATA",
  GET_NIVELES_ERROR: "GET_NIVELES_ERROR",

  GET_FECHAANIMO: "GET_FECHAANIMO",
  GET_FECHAANIMO_DATA: "GET_FECHAANIMO_DATA",
  GET_FECHAANIMO_ERROR: "GET_FECHAANIMO_ERROR",

  SAVE_ANIMO: "SAVE_ANIMO",
  SAVE_ANIMO_DATA: "SAVE_ANIMO_DATA",
  SAVE_ANIMO_ERROR: "SAVE_ANIMO_ERROR",

  GET_PROTOCOLOS: "GET_PROTOCOLOS",
  GET_PROTOCOLOS_DATA: "GET_PROTOCOLOS_DATA",
  GET_PROTOCOLOS_ERROR: "GET_PROTOCOLOS_ERROR",



  GET_PROFESIONES:"GET_PROFESIONES",
  GET_PROFESIONES_DATA: "GET_PROFESIONES_DATA",
  GET_PROFESIONES_ERROR: "GET_PROFESIONES_ERROR",
  
  GET_ESPECIALIDADES:"GET_ESPECIALIDADES",
  GET_ESPECIALIDADES_DATA: "GET_ESPECIALIDADES_DATA",
  GET_ESPECIALIDADES_ERROR: "GET_ESPECIALIDADES_ERROR",


  GET_SERVICIOS:"GET_SERVICIOS",
  GET_SERVICIOS_DATA: "GET_SERVICIOS_DATA",
  GET_SERVICIOS_ERROR: "GET_SERVICIOS_ERROR",


  GET_NOTIFICACIONES: "GET_NOTIFICACIONES",
  GET_NOTIFICACIONES_DATA: "GET_NOTIFICACIONES_DATA",
  GET_NOTIFICACIONES_ERROR: "GET_NOTIFICACIONES_ERROR",

  GET_NOTIFICACIONESSEND: "GET_NOTIFICACIONESSEND",
  GET_NOTIFICACIONESSEND_DATA: "GET_NOTIFICACIONESSEND_DATA",
  GET_NOTIFICACIONESSEND_ERROR: "GET_NOTIFICACIONESSEND_ERROR",


  GET_LAST_USER : "GET_LAST_USER",
  GET_LAST_USER_DATA : "GET_LAST_USER_DATA",
  GET_LAST_USER_ERROR : "GET_LAST_USER_ERROR",

  GET_LAST_USER_DATA_CLEAR : "GET_LAST_USER_DATA_CLEAR",


  UPDATE_USER : "UPDATE_USER",
  UPDATE_USER_DATA : "UPDATE_USER_DATA",
  UPDATE_USER_ERROR : "UPDATE_USER_ERROR",

  INIT_TIME_USER : "INIT_TIME_USER",
  INIT_TIME_USER_DATA : "INIT_TIME_USER_DATA",
  INIT_TIME_USER_ERROR : "INIT_TIME_USER_ERROR",
  INIT_TIME_USER_DATA_CLEAR : "INIT_TIME_USER_DATA_CLEAR",

  END_TIME_USER : "END_TIME_USER",
  END_TIME_USER_DATA : "END_TIME_USER_DATA",
  END_TIME_USER_ERROR : "END_TIME_USER_ERROR",
  END_TIME_USER_DATA_CLEAR : "END_TIME_USER_DATA_CLEAR",

  END_DAY_USER : "END_DAY_USER",
  END_DAY_USER_DATA : "END_DAY_USER_DATA",
  END_DAY_USER_ERROR : "END_DAY_USER_ERROR",
  END_DAY_USER_DATA_CLEAR : "END_DAY_USER_DATA_CLEAR",

  SAVE_H:"SAVE_H",
  SAVE_H_DATA:"SAVE_H_DATA",

  MOOD_DAY_USER : "MOOD_DAY_USER",
  MOOD_DAY_USER_DATA : "MOOD_DAY_USER_DATA",
  MOOD_DAY_USER_ERROR : "MOOD_DAY_USER_ERROR",
  MOOD_DAY_USER_DATA_CLEAR : "MOOD_DAY_USER_DATA_CLEAR",

  GET_HOURSDAY_USER:"GET_HOURSDAY_USER",
  GET_HOURSDAY_USER_DATA:"GET_HOURSDAY_USER_DATA",
  GET_HOURSDAY_USER_DATA_CLEAR:"GET_HOURSDAY_USER_DATA_CLEAR",
  GET_HOURSDAY_USER_ERROR:"GET_HOURSDAY_USER_ERROR",

  SAVE_HOURS_PAST:"SAVE_HOURS_PAST",
  SAVE_HOURS_PAST_DATA:"SAVE_HOURS_PAST_DATA",
  SAVE_HOURS_PAST_DATA_CLEAR:"SAVE_HOURS_PAST_DATA_CLEAR",
  SAVE_HOURS_PAST_DATA_ERROR:"SAVE_HOURS_PAST_DATA_ERROR",

  GET_PERMISSIONS:"GET_PERMISSIONS",
  GET_PERMISSIONS_DATA:"GET_PERMISSIONS_DATA",
  GET_PERMISSIONS_ERROR:"GET_PERMISSIONS_ERROR",

  SAVE_PERMISSIONS:"SAVE_PERMISSIONS",
  SAVE_PERMISSIONS_DATA:"SAVE_PERMISSIONS_DATA",
  SAVE_PERMISSIONS_ERROR:"SAVE_PERMISSIONS_ERROR",
  CLEAR_PERMISSIONS_DATA:"CLEAR_PERMISSIONS_DATA",

  DEL_TIMEDAY:"DEL_TIMEDAY",
  DEL_TIMEDAY_DATA:"DEL_TIMEDAY_DATA",
  DEL_TIMEDAY_ERROR:"DEL_TIMEDAY_ERROR",
  CLEAR_DEL_TIMEDAY: "CLEAR_DEL_TIMEDAY",

}
