import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";

export default function* watcherCounter() {
  yield takeEvery(actionTypes.INCREMENT_COUNTER_DOUBLE, incrementDouble);
  yield takeEvery(actionTypes.DECREMENT_COUNTER_DOUBLE, decrementDouble);
}

export const getCounterValue = state => state.countReducer.counter;

function* incrementDouble() {
  try {
    let counterValue = yield select(getCounterValue); // <-- get the value of counter in state of redux
    var finalCounter = counterValue + 2;
    yield put({ type: actionTypes.SET_COUNTER, payload: finalCounter });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}

function* decrementDouble() {
  try {
    let counterValue = yield select(getCounterValue); // <-- get the value of counter in state of redux
    var finalCounter = counterValue - 2;
    yield put({ type: actionTypes.SET_COUNTER, payload: finalCounter });
  } catch (e) {
    yield put({ type: "API_ERRORED", payload: e });
  }
}
