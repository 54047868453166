import { createStore,  applyMiddleware, compose } from "redux";
import rootReducer from "_reducers/root.reducer";
import createSagaMiddleware from "redux-saga";
import rootSaga from "_sagas/root.saga";

export const loadState = () => {
  try {
    const serializedState = localStorage.getItem("state");
    if (!serializedState) return undefined;
    else {
      // dispatch(userActions.setFullInfo(localStorage.getItem("userDetails")))
      return JSON.parse(serializedState);
    }
  } catch (err) {
    return undefined;
  }
};

export const saveState = state => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("state", serializedState);
  } catch (err) {}
};

const persistedStore = loadState();

const initialiseSagaMiddleware = createSagaMiddleware();
const storeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
  rootReducer,
  storeEnhancers(applyMiddleware(initialiseSagaMiddleware))
);
initialiseSagaMiddleware.run(rootSaga);
store.subscribe(() => {
  saveState(store.getState());
});

export default store;
