import actionTypes from "_constants/actionTypes";

export const authActions = {
  login,
  logout,
  reset,
  endTransaction,
  change,
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function reset(email) {
  var payload = { email };
  return {
    type: actionTypes.RESET_USER,
    payload: payload,
  };
}
function change(email, password, passwordrep) {
  var payload = { email, password, passwordrep };
  return {
    type: actionTypes.CHANGEPASS_USER,
    payload: payload,
  };
}

function logout() {
  return {
    type: actionTypes.LOGOUT,
    payload: "",
  };
}

function login(email, password,remember) {
  var payload = { email, password,remember };
  return {
    type: actionTypes.LOGIN_USER,
    payload: payload,
  };
}
