import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import routes from "routes.js";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import { Layout, Row, Col, Menu, Dropdown, Avatar } from "antd";
import logo from "assets/img/logoblanco.png";
import {
  UploadOutlined,
  UserOutlined,
  CalendarOutlined,
  AppstoreOutlined,
  VideoCameraOutlined,
} from "@ant-design/icons";
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";
import { TabBar } from "antd-mobile";
import { withTranslation } from "react-i18next";
import { useTranslation } from "react-i18next";
import { history } from "_helpers/history";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";

import { useSelector, useDispatch } from "react-redux";
import { authActions, sessionsActions } from "_actions";
import {AiFillDashboard} from 'react-icons/ai';
import {BsCalendarWeek, BsCalendarWeekFill,BsCalendarRangeFill,BsCalendarRange } from 'react-icons/bs'



import "./Admin.scss";
const { Header, Content, Footer, Sider } = Layout;

let ps;
const switchRoutes = (
  <Switch>
    {routes.map((prop, key) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      }
      return null;
    })}
  </Switch>
);

function Admin({ ...rest }) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const storage = localOrSessionStore({ key: variables.REMEMBER });
  const user = JSON.parse(storage.getItem(variables.USER));
  const logout = () => {
    dispatch(authActions.logout());
  };
  const menu = (
    <Menu>
      <Menu.Item key="1">
        <a href="/admin/account">{t("menu.account")}</a>
      </Menu.Item>
      <Menu.Divider />
      <Menu.Item key="2" onClick={logout}>
        {t("logout")}
      </Menu.Item>
    </Menu>
  );

  const name = user.name + " " + user.surname;
  
  let menuItems = [
    {
      name: "menu.dashboard",
      icon: <AiFillDashboard />,
      link: "/admin/dashboard",
      key: 1,
    },

    {
      name: "menu.calendar",
      icon: <BsCalendarWeekFill />,
      link: "/admin/calendar",
      key: 2,
    },

    {
      name: "menu.vacaciones",
      icon: <BsCalendarRange />,
      link: "/admin/vacaciones",
      key: 6,
    },

    {
      name: "menu.account",
      icon: <UserOutlined />,
      link: "/admin/account",
      key: 3,
    },
  ];

  const getCurrentRoute = () => {
    var ret = ["1"];

    menuItems.map((item) => {
      if (rest.location.pathname.includes(item.link)) {
        ret = ["" + item.key + ""];
      }
    });

    return ret;
  };

  const isSelected = (id) => {
    let ret = false;
    let itMenu = menuItems.filter((item) => {
      return item.key === id;
    });

    if (itMenu && itMenu[0]) {
      if (itMenu[0].link === rest.location.pathname) ret = true;
    }

    return ret;
  };

  

  return (
    <>
      <Layout>
        <Header
          className="site-layout-sub-header-background"
          style={{ padding: 0, zIndex: 400 }}
        >
          <div className="logo">
            <img src={logo} />
          </div>
          <div className="actions">
            <Dropdown overlay={menu} trigger={["click"]}>
              <span className="user-action">
                {user.avatar ? (
                  <Avatar src={user.avatar} />
                ) : (
                  <Avatar style={{ color: "#000", backgroundColor: "#fff" }}>
                    {name
                      .split(" ")
                      .map((n) => n[0])
                      .join("")}
                  </Avatar>
                )}
              </span>
            </Dropdown>
          </div>
        </Header>

        <div
          style={{
            position: "fixed",
            height: "100%",
            width: "100%",
            top: 0,
            zIndex: 0,
          }}
        >
          <TabBar
            unselectedTintColor="#FFFFFF"
            tintColor="#879CAF"
            barTintColor="#001529"
            tabBarPosition="bottom"
            prerenderingSiblingsNumber={0}
          >
            {menuItems.map((menuItem) => {
              return (
                menuItem.name && (
                  <TabBar.Item
                    title={t(menuItem.name)}
                    key={menuItem.key}
                    icon={menuItem.icon}
                    selectedIcon={menuItem.icon}
                    data-seed={menuItem.name}
                    selected={isSelected(menuItem.key)}
                    onPress={() => {
                      history.push(menuItem.link);
                    }}
                  ><Content className="allContent">
                      <div
                        className="site-layout-background"
                        style={{ padding: 14, minHeight: 780 }}
                      >
                        {switchRoutes}
                      </div>
                    </Content>
                  </TabBar.Item>
                )
              );
            })}
          </TabBar>
        </div>
      </Layout>
    </>
  );
}
export default withTranslation()(Admin);
