const { localStorage, sessionStorage } = global;

export default function localOrSessionStore({ key }) {
  let shouldPersist = localStorage.getItem(key) === 't';
  return {
    setShouldPersist(persist) {
      shouldPersist = !!persist;
      if (persist) {
        localStorage.setItem(key, 't');
      }  else {
        localStorage.removeItem(key);
      }
    },

    getItem(name) {
      if (shouldPersist) {
        return localStorage.getItem(name);
      }

      return sessionStorage.getItem(name);
    },

    setItem(name, value) {
      if (shouldPersist) {
        return localStorage.setItem(name, value);
      }

      return sessionStorage.setItem(name, value);
    },

    removeItem(name) {
      if (shouldPersist) {
        return localStorage.removeItem(name);
      }

      return sessionStorage.removeItem(name);
    },
  };
}