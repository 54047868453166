import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
import variables from "_constants/variables";
import config from "_config/config";
import localOrSessionStore from "_helpers/localOrSessionStore";
export default function* watcherAuth() {
  yield takeEvery(actionTypes.LOGOUT, logoutUser);
  yield takeEvery(actionTypes.LOGIN_USER, loginUser);
  yield takeEvery(actionTypes.CHANGEPASS_USER, changePass);
  yield takeEvery(actionTypes.RESET_USER, resetUser);
}


function* resetUser({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/forgot";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.RESET_USER_DATA,
        payload: res.data,
      });
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.RESET_USER_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.RESET_USER_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* logoutUser() {
  // console.log(999, "logout2");
  const storage = localOrSessionStore({ key: variables.REMEMBER });
  yield put({ type: actionTypes.START_REQUEST });
  storage.setItem(variables.AUTH, "false");
  storage.setItem(variables.COMPLET, "0");
  storage.removeItem(variables.TOKEN);
  storage.removeItem(variables.TOKENREFRESH);
  storage.removeItem(variables.USER);
  yield put({ type: actionTypes.END_REQUEST });
  history.push("/auth/login");
}



function* changePass({ payload }) {
  const storage = localOrSessionStore({ key: variables.REMEMBER });
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/change";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res) {
      if (res.status && res.status === 200 && res.data.token) {
        storage.setItem(variables.AUTH, "true");
        storage.setItem(variables.COMPLET, "0");
        storage.setItem(variables.TOKEN, res.data.token);
        storage.setItem(variables.TOKENREFRESH, res.data.refreshToken);
        storage.setItem(variables.USER, JSON.stringify(res.data.user));
        localStorage.setItem(
          variables.TOUR,
          storage.getItem(variables.TOUR) !== (undefined && null)
            ? storage.getItem(variables.TOUR)
            : true
        );
        // window.location.href=`${config.backendUrlImg}/v1/gotocalendly`;
        setTimeout(() => {
          history.push("/admin/dashboard");
        }, 100);
      } else {
        yield put({
          type: actionTypes.CHANGEPASS_USER_ERROR,
          payload: res.data,
        });
      }
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.CHANGEPASS_USER_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.CHANGEPASS_USER_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* loginUser({ payload }) {
  // console.log(121, payload);
  if (payload.remember) {
    localStorage.setItem(variables.REMEMBER, "t");
  } else {
    localStorage.setItem(variables.REMEMBER, "f");
  }
  const storage = localOrSessionStore({ key: variables.REMEMBER });
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/auth/login";
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.post(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res) {
      if (res.status && res.status === 200 && res.data.token) {
       

        if (res.data.user.needChange === true) {
          sessionStorage.setItem(variables.EMAIL, res.data.user.email);
          setTimeout(() => {
            history.push("/auth/change");
          }, 100);

        }else{
          storage.setItem(variables.AUTH, "true");
          storage.setItem(variables.COMPLET, "0");
          storage.setItem(variables.TOKEN, res.data.token);
          storage.setItem(variables.TOKENREFRESH, res.data.refreshToken);
          storage.setItem(variables.USER, JSON.stringify(res.data.user));
          localStorage.setItem(
            variables.TOUR,
            storage.getItem(variables.TOUR) !== (undefined && null)
              ? storage.getItem(variables.TOUR)
              : true
          );
          yield put({ type: actionTypes.LOGIN_USER_DATA, payload: res.data });
          setTimeout(() => {
            history.push("/admin/dashboard");
          }, 100);
        }
      

        // }else{
        //     history.push("/admin/dashboard");
        // }
      } else {
        yield put({ type: actionTypes.LOGIN_USER_ERROR, payload: res.data });
      }
    } else {
      yield put({
        type: actionTypes.LOGIN_USER_ERROR,
        payload: { codeMsg: "001" },
      });
    }
  } catch (e) {
    yield put({
      type: actionTypes.LOGIN_USER_ERROR,
      payload: { codeMsg: "001" },
    });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
