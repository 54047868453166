import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
import variables from "_constants/variables";
import config from "_config/config";
import localOrSessionStore from "_helpers/localOrSessionStore";
export default function* watcherPermissions() {
  yield takeEvery(actionTypes.GET_PERMISSIONS, getPermissions);
  yield takeEvery(actionTypes.SAVE_PERMISSIONS, savePermission);
}


function* getPermissions({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/permission";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_PERMISSIONS_DATA,
        payload: res.data,
      });
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_PERMISSIONS_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_PERMISSIONS_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* savePermission({ payload }) {
    yield put({ type: actionTypes.START_REQUEST });
  
    try {
      let apiEndpoint = "/v1/permission";
      // console.log(payload);
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
            service.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      console.log(res.status)
      if (res.status === 200) {
        yield put({
          type: actionTypes.SAVE_PERMISSIONS_DATA,
          payload: "ok",
        });
      } else {
        // console.log(res);
        yield put({
          type: actionTypes.SAVE_PERMISSIONS_ERROR,
          payload: res.data,
        });
      }

      yield put({
        type: actionTypes.CLEAR_PERMISSIONS_DATA,
        payload: res.data,
      });

      yield put({
        type: actionTypes.GET_PERMISSIONS,
        payload: res.data,
      });

    } catch (e) {
      yield put({ type: actionTypes.SAVE_PERMISSIONS_ERROR, payload: e });

      yield put({
        type: actionTypes.CLEAR_PERMISSIONS_DATA,
        payload: "o",
      });

      yield put({
        type: actionTypes.GET_PERMISSIONS,
        payload:"",
      });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }