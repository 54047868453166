export default {
    EMAIL: "clockliEmailAuth",
    REMEMBER: "clockliPortalRemember",
    TOKEN: "clockliPortalToken", 
    AUTH: "clockliPortalAuth",
    COMPLET: "clockliPortalCompleto",
    TOKENREFRESH: "clockliPortalRefreshToken",
    USER: "clockliPortalUser", 
    TOUR: "clockliPortalTour"

}