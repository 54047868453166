import actionTypes from "_constants/actionTypes";
import { takeEvery, call, put, select } from "redux-saga/effects";
import {service} from "../_services/service";
export default function* sagaTime() {
    yield takeEvery(actionTypes.GET_LAST_USER, getLastTime);
    yield takeEvery(actionTypes.INIT_TIME_USER, initTime);
    yield takeEvery(actionTypes.END_TIME_USER, endTime);
    yield takeEvery(actionTypes.END_DAY_USER, endDay);
    yield takeEvery(actionTypes.MOOD_DAY_USER, moodDay);  

    yield takeEvery(actionTypes.GET_HOURSDAY_USER,getHoursDate);
    yield takeEvery(actionTypes.SAVE_H,addHoursDate)

    yield takeEvery(actionTypes.DEL_TIMEDAY,delHoursDate)
  
  }




  function* delHoursDate({payload}) {
    yield put({ type: actionTypes.START_REQUEST });
  
    console.log(payload);
   
    try {
      let apiEndpoint = "/v1/timeday/" + payload;
   
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.deleteToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
    
      if (res.status === 200 && res.data) {
        
        yield put({ type: actionTypes.DEL_TIMEDAY_DATA, payload: res.data });
  
        yield put({ type: actionTypes.CLEAR_DEL_TIMEDAY, payload: "OK" });
      } else {
       
        yield put({ type: actionTypes.DEL_TIMEDAY_ERROR, payload: res.data });
        yield put({ type: actionTypes.CLEAR_DEL_TIMEDAY, payload: "OK" });
      }
    } catch (e) {
      yield put({ type: actionTypes.DEL_TIMEDAY_ERROR, payload: e });
      yield put({ type: actionTypes.CLEAR_DEL_TIMEDAY, payload: "OK" });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }


  function* addHoursDate({payload}) {
    yield put({ type: actionTypes.START_REQUEST });
    try {
      let apiEndpoint = "/v1/timeday"
   
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.postToken(apiEndpoint, payload.body).then((response) => {
              resolve(response);
            });
          })
      );
     
      if (res.status === 200 ) {
        yield put({ type: actionTypes.SAVE_HOURS_PAST_DATA, payload: res.data });

        yield put({ type: actionTypes.SAVE_HOURS_PAST_DATA_CLEAR, payload: res.data });
       
      } else {
        console.log("126");
        yield put({ type: actionTypes.SAVE_HOURS_PAST_DATA_ERROR, payload: res.data });
      }
    } catch (e) {
      console.log("127");
      yield put({ type: actionTypes.SAVE_HOURS_PAST_DATA_ERROR, payload: e });
    }
   
  }
  



  function* moodDay({payload}) {
    yield put({ type: actionTypes.START_REQUEST });
   
    try {
      let apiEndpoint = "/v1/mood"
   
      console.log(15,apiEndpoint)
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
     
      if (res.status === 200 ) {
       
        yield put({ type: actionTypes.MOOD_DAY_USER_DATA, payload: res.data });
  
        yield put({ type: actionTypes.MOOD_DAY_USER_DATA_CLEAR, payload: res.data });
      } else {
       
        yield put({ type: actionTypes.MOOD_DAY_USER_ERROR, payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.MOOD_DAY_USER_ERROR, payload: e });
    }
    yield put({ type: actionTypes.MOOD_REQUEST });
  }

  function* endDay(payload) {
    yield put({ type: actionTypes.START_REQUEST });
   
    try {
      let apiEndpoint = "/v1/time/end"
   
      console.log(15,apiEndpoint)
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.putToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      console.log(res);
      if (res.status === 200 ) {
       
        yield put({ type: actionTypes.END_DAY_USER_DATA, payload: res.data });
  
        yield put({ type: actionTypes.END_DAY_USER_DATA_CLEAR, payload: res.data });
      } else {
       
        yield put({ type: actionTypes.END_DAY_USER_ERROR, payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.END_DAY_USER_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  
  function* endTime(payload) {
    yield put({ type: actionTypes.START_REQUEST });
  
    console.log(13,payload.payload.time.id);
   
    try {
      let apiEndpoint = "/v1/time/stop/" + payload.payload.time.id;
   
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.putToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      if (res.status === 200 ) {
       
        yield put({ type: actionTypes.END_TIME_USER_DATA, payload: res.data });
  
        yield put({ type: actionTypes.END_TIME_USER_DATA_CLEAR, payload: res.data });
      } else {
       
        yield put({ type: actionTypes.END_TIME_USER_ERROR, payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.END_TIME_USER_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  function* initTime(payload) {
    yield put({ type: actionTypes.START_REQUEST });
  
    try {
      let apiEndpoint = "/v1/time";
   
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.postToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
      console.log(18,res.status);
      if (res.status === 200 ) {
       
        yield put({ type: actionTypes.INIT_TIME_USER_DATA, payload: res.data });
  
        yield put({ type: actionTypes.INIT_TIME_USER_DATA_CLEAR, payload: res.data });
      } else {
       
        yield put({ type: actionTypes.INIT_TIME_USER_ERROR, payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.INIT_TIME_USER_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  


  function* getHoursDate({payload}) {
    yield put({ type: actionTypes.START_REQUEST });
  
    console.log(payload);
   
    try {
      let apiEndpoint = "/v1/timeday/" + payload;
   
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.getToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
    
      if (res.status === 200 && res.data) {
        
        yield put({ type: actionTypes.GET_HOURSDAY_USER_DATA, payload: res.data });
  
        yield put({ type: actionTypes.GET_HOURSDAY_USER_DATA_CLEAR, payload: res.data });
      } else {
       
        yield put({ type: actionTypes.GET_HOURSDAY_USER_ERROR, payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.GET_HOURSDAY_USER_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  
  function* getLastTime(payload) {
    yield put({ type: actionTypes.START_REQUEST });
  
   
    try {
      let apiEndpoint = "/v1/time/last";
   
      const res = yield call(
        () =>
          new Promise((resolve, reject) => {
              service.getToken(apiEndpoint, payload).then((response) => {
              resolve(response);
            });
          })
      );
    
      if (res.status === 200 && res.data) {
        
        yield put({ type: actionTypes.GET_LAST_USER_DATA, payload: res.data });
  
        yield put({ type: actionTypes.GET_LAST_USER_DATA_CLEAR, payload: res.data });
      } else {
       
        yield put({ type: actionTypes.GET_LAST_USER_ERROR, payload: res.data });
      }
    } catch (e) {
      yield put({ type: actionTypes.GET_LAST_USER_ERROR, payload: e });
    }
    yield put({ type: actionTypes.END_REQUEST });
  }
  