import axios from "axios";
import config from "_config/config";
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";

export const axiosInstance = axios.create();

const storage = localOrSessionStore({ key: variables.REMEMBER });

axiosInstance.interceptors.request.use(
  (config) => {
    if (!config.headers.Authorization) {
      const token = storage.getItem(variables.TOKEN);
      
      if (token) {
        config.headers = {
          Authorization: `bearer ${token}`,
          "Content-Type": "application/json",
        };
       
      }
    }
    return config;
  },
  (error) => {
    console.log(error);
    // store.dispatch(setAPIErrorMessage(error.message));
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const originalRequest = error.config;

    const refreshToken = storage.getItem(variables.TOKENREFRESH);
    const user = JSON.parse(storage.getItem(variables.USER));
   
    if (refreshToken && user) {
      if (error.response.status === 498 && !originalRequest._retry) {
        originalRequest._retry = true;

        return axios
          .post(config.backendUrl + "/v1/auth/refresh", {
            refreshToken: refreshToken,
            username: user.email,
          })
          .then(({data}) => {
            
            storage.setItem(variables.TOKEN, data.token);
            storage.setItem(
              variables.TOKENREFRESH,
              data.refreshToken
            );

            axios.defaults.headers.common["Authorization"] =
              "Bearer " + data.token;
            originalRequest.headers["Authorization"] =
              "Bearer " + data.token;
            return axiosInstance(originalRequest);
          });
      }
    }

    return Promise.reject(error);
  }
);
