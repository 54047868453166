import actionTypes from "_constants/actionTypes";

const initialState = {};
// Reducers (Modifies The State And Returns A New State)
const timeReducer = (state = initialState, action) => {
  switch (action.type) {
    // Login

    case actionTypes.SAVE_H: {
      return {
        ...state,
          addHoursDay: action.payload,
      };
    }
   

    case actionTypes.GET_HOURSDAY_USER_DATA: {
      return {
        ...state,
        hoursDayError: null,
        hoursDay: action.payload,
      };
    }
    case actionTypes.GET_HOURSDAY_USER_DATA_CLEAR: {
      return {
        ...state,
        hoursDayError: null,
        hoursDay: null,
      };
    }
    case actionTypes.GET_HOURSDAY_USER_ERROR: {
      return {
        ...state,
        hoursDay: null,
        hoursDayError: action.payload,
      };
    }



    case actionTypes.GET_LAST_USER_DATA: {
      return {
        ...state,
        lastUserError: null,
        lastUser: action.payload,
      };
    }
    case actionTypes.GET_LAST_USER_DATA_CLEAR: {
      return {
        ...state,
        lastUserError: null,
        lastUser: null,
      };
    }
    case actionTypes.GET_LAST_USER_ERROR: {
      return {
        ...state,
        lastUser: null,
        lastUserError: action.payload,
      };
    }


    case actionTypes.INIT_TIME_USER_DATA: {
      return {
        ...state,
        initTimeUserError: null,
        initTimeUser: action.payload,
      };
    }
    case actionTypes.INIT_TIME_USER_DATA_CLEAR: {
      return {
        ...state,
        initTimeUserError: null,
        initTimeUser: null,
      };
    }
    case actionTypes.INIT_TIME_USER_ERROR: {
      return {
        ...state,
        initTimeUser: null,
        initTimeUserError: action.payload,
      };
    }


    case actionTypes.END_TIME_USER_DATA: {
      return {
        ...state,
        endTimeUserError: null,
        endTimeUser: action.payload,
      };
    }
    case actionTypes.END_TIME_USER_DATA_CLEAR: {
      return {
        ...state,
        endTimeUserError: null,
        endTimeUser: null,
      };
    }
    case actionTypes.END_TIME_USER_ERROR: {
      return {
        ...state,
        endTimeUser: null,
        endTimeUserError: action.payload,
      };
    }


    case actionTypes.END_DAY_USER_DATA: {
      return {
        ...state,
        endDayUserError: null,
        endDayUser: action.payload,
      };
    }
    case actionTypes.END_DAY_USER_DATA_CLEAR: {
      return {
        ...state,
        endDayUserError: null,
        endDayUser: null,
      };
    }
    case actionTypes.END_DAY_USER_ERROR: {
      return {
        ...state,
        endDayUser: null,
        endDayUserError: action.payload,
      };
    }


    case actionTypes.MOOD_DAY_USER_DATA: {
      return {
        ...state,
        moodDayUserError: null,
        moodDayUser: action.payload,
      };
    }
    case actionTypes.MOOD_DAY_USER_DATA_CLEAR: {
      return {
        ...state,
        moodDayUserError: null,
        moodDayUser: null,
      };
    }
    case actionTypes.MOOD_DAY_USER_ERROR: {
      return {
        ...state,
        moodDayUser: null,
        moodDayUserError: action.payload,
      };
    }

    case actionTypes.SAVE_HOURS_PAST_DATA: {
      return {
        ...state,
        hoursDataSaved: action.payload,
      };
    }

    case actionTypes.SAVE_HOURS_PAST_DATA_CLEAR: {
      return {
        ...state,
        hoursDataSaved: null,
      };
    }


    case actionTypes.DEL_TIMEDAY_DATA: {
      return {
        ...state,
        delTimeDay: "ok",
      };
    }

    case actionTypes.DEL_TIMEDAY_ERROR: {
      return {
        ...state,
        delTimeDayError: "ok",
      };
    }

    case actionTypes.CLEAR_DEL_TIMEDAY: {
      return {
        ...state,
        delTimeDay: null,
        delTimeDayError:null
      };
    }

    // Default
    default: {
      return state;
    }
  }
};
// Exports
export default timeReducer