import axios from "axios";
import config from "_config/config";
import { axiosInstance } from "./interceptor";

export const service = {
  get,
  post,
  put,
  patchToken,
  postToken,
  getToken,
  putToken,
  deleteToken,
};

async function get(apiEndpoint) {
  return axios
    .get(config.backendUrl + apiEndpoint, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}
function post(apiEndpoint, payload) {
  return axios
    .post(config.backendUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}
function put(apiEndpoint, payload) {
  return axios
    .put(config.backendUrl + apiEndpoint, payload, getOptions())
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function getToken(apiEndpoint) {
  return axiosInstance
    .get(config.backendUrl + apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function postToken(apiEndpoint, payload) {
  return axiosInstance
    .post(config.backendUrl + apiEndpoint, payload)
    .then((response) => {
    
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

function putToken(apiEndpoint, payload) {
  return axiosInstance
    .put(config.backendUrl + apiEndpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function patchToken(apiEndpoint, payload) {
  return axiosInstance
    .patch(config.backendUrl + apiEndpoint, payload)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

async function deleteToken(apiEndpoint) {
  return axiosInstance
    .delete(config.backendUrl + apiEndpoint)
    .then((response) => {
      return response;
    })
    .catch((err) => {
      return err.response;
    });
}

function getOptions() {
  let options = {};

  options.headers = { "Content-Type": "application/json" };

  return options;
}
