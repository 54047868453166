import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import queryString from "query-string";
import { useLocation } from "react-router";
import { Result, Button } from "antd";
function ErrorPage(props) {
  const dispatch = useDispatch();
  const { t, i18n } = props;
  const location = useLocation();

  useEffect(() => {}, []);

  return (
    <>

      <div style={{marginTop: 150}}>
      <center>
      <Result
        status="warning"
        title="There are some problems with your operation."
        extra={
          <Button type="primary" key="console" onClick={(e) => window.location.href='/admin/dashboard'}>
            Go Home
          </Button>
        }
      />
      </center></div>
    </>
  );
}

export default withTranslation()(ErrorPage);
