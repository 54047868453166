import React from "react";
import { Route, Redirect } from "react-router-dom";
import Admin from "layouts/Admin.js";
import Auth from "layouts/Auth.js";
import localOrSessionStore from "_helpers/localOrSessionStore";
import variables from "_constants/variables";

export const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    component={(props) => {
      const storage = localOrSessionStore({ key: variables.REMEMBER });

      var token = storage.getItem(variables.TOKEN);

      const user = JSON.parse(storage.getItem(variables.USER));

      if (!token) {
        return <Redirect to="/auth/login" />;
      } else {
        if (user.changePass){
          return <Redirect to="/auth/change" />;
        }
        var path = props.location.pathname;

        if (path.includes("/admin")) {
          return <Admin {...props} />;
        }
      }

      return (
        <Redirect to={{ pathname: "/", state: { from: props.location } }} />
      );
    }}
  />
);
