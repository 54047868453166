import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';

i18n
  
  .use(Backend)
    .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'es',
    debug: true,
    backend: {
        /* translation file path */
        loadPath: '/assets/i18n/{{ns}}/{{lng}}.json'
      },
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

  console.log(i18n)

export default i18n