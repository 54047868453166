import actionTypes from "_constants/actionTypes";
export const permissionsActions = {
    newPermissions,
  endTransaction,getPermissions
};
function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}


function newPermissions(payload) {
  return {
    type: actionTypes.SAVE_PERMISSIONS,
    payload,
  };
}

function getPermissions() {
  const payload = "";
  return {
    type: actionTypes.GET_PERMISSIONS,
    payload,
  };
}