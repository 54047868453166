import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.GET_PERMISSIONS_DATA:
      return {
        ...state,
        getPermissionsError: null,
        getPermissions: action.payload,
      };
    case actionTypes.GET_PERMISSIONS_ERROR:
      return {
        ...state,
        getPermissions: null,
        getPermissionsError: action.payload,
      };
      case actionTypes.SAVE_PERMISSIONS_DATA:
        return {
          ...state,
          savePermissionsError: null,
          savePermissions: action.payload,
        };
      case actionTypes.SAVE_PERMISSIONS_ERROR:
        return {
          ...state,
          savePermissions: null,
          savePermissionsError: action.payload,
        };
        case actionTypes.CLEAR_PERMISSIONS_DATA:
          return {
            ...state,
            savePermissions: null,
            savePermissionsError: null,
          };
    default:
      return {
        ...state,
      };
  }
};
