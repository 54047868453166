import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox } from "antd";
import { Row, Col, Divider, Alert, Rate, Table } from "antd";
import { DatePicker, Space, message } from "antd";
import { useTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";
import moment from "moment";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/img/logo2.png";
import { permissionsActions } from "_actions/";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";
import { Typography } from "antd";

import "./index.scss";

const { RangePicker } = DatePicker;

const { Title, Paragraph, Text } = Typography;
const storage = localOrSessionStore({ key: variables.REMEMBER });
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 8,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

function PermissionPage(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [form] = Form.useForm();
  const user = JSON.parse(storage.getItem(variables.USER));

  var getPermissions = useSelector(
    (state) => state.permissionReducer.getPermissions
  );
  var getPermissionsError = useSelector(
    (state) => state.permissionReducer.getPermissionsError
  );

  var savePermissions = useSelector(
    (state) => state.permissionReducer.savePermissions
  );
  var savePermissionsError = useSelector(
    (state) => state.permissionReducer.savePermissionsError
  );

  const getPermissionsList = () => {
    dispatch(permissionsActions.getPermissions());
  };

  useEffect(() => {
    if (getPermissions) setData(getPermissions);
  }, [getPermissions]);

  useEffect(() => {
    getPermissionsList();
  }, []);

  useEffect(() => {
   if (savePermissionsError || savePermissions) {
    message.success(t("permissions.success"));
    form.resetFields();
    const { setFieldsValue, getFieldValue } = form;

    setFieldsValue({initDate: null, endDate: null});

   }
  }, [savePermissions, savePermissionsError]);

  function disabledDate(current) {
    // Can not select days before today and today
    return current && current < moment().endOf('day');
  }

  const columns = [
    {
      title: t("permissions.from"),
      dataIndex: "from",
      key: "from",
      render: (text, record) => (
        <span>{moment(record.initDate).format("DD/MM/YYYY")}</span>
       ),
    },
    {
      title: t("permissions.to"),
      dataIndex: "to",
      key: "to",
      render: (text, record) => (
        <span>{moment(record.endDate).format("DD/MM/YYYY")}</span>
       ),
    },
    {
      title: t("permissions.approved"),
      dataIndex: "approved",
      key: "approved",
      render: (text, record) => (
        (record.approvedById) && <span>{t("permissions.approved")} ({record.approvedBy.name})</span>
       ),
    },
  ];

  const onFinish = (data) => {
    if (data.from.isAfter(data.to)) {
      message.error(t("permissions.errorF"));
    } else {
      const payload = {
        initDate: moment(data.from).format("YYYY-MM-DD"),
        endDate: moment(data.to).format("YYYY-MM-DD"),
        typepermissionId: 1,
      };
      console.log(payload);
      dispatch(permissionsActions.newPermissions(payload));
    }
  };

  return (
    <>
      <div>
        <Form
          {...formItemLayout}
          form={form}
          name="register"
          onFinish={onFinish}
          scrollToFirstError
        >
          <Form.Item name="from" label={t("permissions.from")}>
            <DatePicker  disabledDate={disabledDate} format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item name="to" label={t("permissions.to")}>
            <DatePicker   disabledDate={disabledDate} format="DD/MM/YYYY" />
          </Form.Item>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit">
              {t("soli")}
            </Button>
          </Form.Item>
        </Form>
      </div>

{console.log(data)}
      {data && (
        <Table dataSource={data} columns={columns} />
      )}
    </>
  );
}
export default withTranslation()(PermissionPage);
