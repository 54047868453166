import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.RESET_USER_DATA:
      return {
        ...state,
        resetUserError: null,
        resetUser: action.payload,
      };
    case actionTypes.RESET_USER_ERROR:
      return {
        ...state,
        resetUser: null,
        resetUserError: action.payload,
      };
    case actionTypes.CHANGEPASS_USER_DATA:
      return {
        ...state,
        changePassUserError: null,
        changePass: action.payload,
      };
    case actionTypes.CHANGEPASS_USER_ERROR:
      return {
        ...state,
        changePass: null,
        changePassUserError: action.payload,
      };
    case actionTypes.LOGIN_USER_DATA:
      return {
        ...state,
        error: null,
        user: action.payload,
      };
    case actionTypes.LOGIN_USER_ERROR:
      return {
        ...state,
        user: null,
        error: action.payload,
      };
    default:
      return {
        ...state,
      };
  }
};
