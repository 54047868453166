import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox } from "antd";
import { Row, Col, Divider, Alert, message } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/img/logo2.png";
import {authActions} from "_actions/";
import variables from "_constants/variables";

import pixel from "assets/img/pixel.png";
import { Link } from "react-router-dom";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
function ForgotPage(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  var resetUser = useSelector((state) => state.authReducer.resetUser);
  var resetUserError = useSelector((state) => state.authReducer.resetUserError);

  var meData = useSelector((state) => state.authReducer.meData);

  useEffect(() => {
    setLoading(false);
    if (resetUser) {
      setError(false);
      setErrorMsg("");
      message.success(t("login.passwordSent"));
    }
    if (resetUserError) {
      setError(true);
      setErrorMsg(t(resetUserError.codeMsg));
    }
  }, [resetUser, resetUserError]);

  const onFinish = (values) => {
    setLoading(true);
    dispatch(authActions.reset(values.email));
  };

  return (
    <>
      <Row justify="space-around" align="middle">
        <Col xl={7} lg={7} md={10} sm={18} xs={20} className="form-padding">
          <div style={{textAlign: "center", marginBottom: 25}}><img src={logo} style={{height: 65}}  /></div>
          
          
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
             <div className="heading">
                {t("login.infoforgot")}
            </div>
            <Form.Item
              name="email"
              rules={[
                {
                  type: "email",
                  message: t("login.emailIncorrect"),
                },
                {
                  required: true,
                  message: t("login.emailMandatory"),
                },
              ]}
            >
              <Input
                prefix={<UserOutlined className="site-form-item-icon" />}
                placeholder={t("login.email")}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                className="login-form-button"
              >
                {t("login.recover")}
              </Button>
              {error && <Alert message={errorMsg} type="error" />}
            </Form.Item>
            <Link to="/auth/login">{t("login.login")}</Link>
          </Form>
          Powered by:<br />
          <img style={{width: 100}} src={pixel}  />
        </Col>
      </Row>
    </>
  );
}
export default withTranslation()(ForgotPage);
