import actionTypes from "_constants/actionTypes";

export const userActions = {
  me,
  password,
  init,
  endTransaction,
  getMe,updateUser,
  niveles,updatePwd,
  logros, puntos, ranking,fechaAnimo,saveAnimo
};

function updatePwd(payload) {
  return {
    type: actionTypes.UPDATE_PWD_AUTH,
    payload: payload,
  };
}



function endTransaction() {
  return {
    type: actionTypes.END_TRANSACTION,
  };
}

function updateUser(payload) {
  return {
    type: actionTypes.UPDATE_USER_AUTH,
    payload: payload,
  };
}

function saveAnimo(animo) {
  var payload = { animo };
  return {
    type: actionTypes.SAVE_ANIMO,
    payload: payload,
  };
}

function fechaAnimo() {
  var payload = "";
  return {
    type: actionTypes.GET_FECHAANIMO,
    payload: payload,
  };
}

function niveles() {
  var payload = "";
  return {
    type: actionTypes.GET_NIVELES,
    payload: payload,
  };
}
function logros() {
  var payload = "";
  return {
    type: actionTypes.GET_LOGROSDATA,
    payload: payload,
  };
}

function puntos() {
  var payload = "";
  return {
    type: actionTypes.GET_PUNTOSDATA,
    payload: payload,
  };
}

function ranking() {
  var payload = "";
  return {
    type: actionTypes.GET_RANKINGDATA,
    payload: payload,
  };
}

function getMe() {
  var payload = "";
  return {
    type: actionTypes.GET_MEDATA,
    payload: payload,
  };
}
function me(email) {
  var payload = { email };
  return {
    type: actionTypes.MEDATA,
    payload: payload,
  };
}
function password( password, passwordrep) {
  var payload = {  password, passwordrep };
  return {
    type: actionTypes.MEPASSWORD,
    payload: payload,
  };
}


function init(tokennoti) {
  var payload = { tokennoti };
  return {
    type: actionTypes.MEINIT,
    payload: payload,
  };
}
