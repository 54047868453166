import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { userActions } from "_actions/";
import {
  Table,
  Tag,
  Space,
  Button,
  Avatar,
  Modal,
  Card,
  Form,
  Input,
  InputNumber,
  Upload,
  message,
  Alert,
} from "antd";
import {
  PlusCircleOutlined,
  EditFilled,
  EyeFilled,
  LockOutlined,
} from "@ant-design/icons";
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import config from "_config/config";

import packageJson from "../../../package.json";
const formItemLayout = {
  labelCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 4,
    },
  },
  wrapperCol: {
    xs: {
      span: 24,
    },
    sm: {
      span: 16,
    },
  },
};
const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const { Meta } = Card;

function Profile(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [formpwd] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [loadingPwd, setLoadingPwd] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");

  const storage = localOrSessionStore({ key: variables.REMEMBER });
  const user = JSON.parse(storage.getItem(variables.USER));
  useEffect(() => {
    if (user && user.avatar) {
      setImageUrl(user.avatar);
    }

    const { setFieldsValue, getFieldValue } = form;

    setFieldsValue({ name: user.name, surname: user.surname });
  }, []);

  var updateUserAuth = useSelector((state) => state.userReducer.updateUserAuth);
  var updatePwdAuth = useSelector((state) => state.userReducer.updatePwdAuth);
  var updatePwdAuthError = useSelector(
    (state) => state.userReducer.updatePwdAuthError
  );

  const uploadButton = () => {
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>;
  };

  useEffect(() => {
    if (updatePwdAuthError) {
      setLoadingPwd(false);
      setError(true);
      setErrorMsg(t(updatePwdAuthError.codeMsg));
    }
  }, [updatePwdAuthError]);

  useEffect(() => {
    setLoading(false);
    setLoadingPwd(false);
    if (updateUserAuth) {
      user.name = updateUserAuth.name;
      user.surname = updateUserAuth.surname;
      user.avatar = updateUserAuth.avatar;

      storage.setItem(variables.USER, JSON.stringify(user));
    }
    if (updatePwdAuth || updateUserAuth) {
      setError(false);
      message.success(t("saved"));
      window.location.reload(false);
    }
  }, [updateUserAuth, updatePwdAuth]);

  const onFinish = (data) => {
    setLoading(false);
    console.log(data);
    data.avatar = imageUrl;
    dispatch(userActions.updateUser(data));
  };

  const onFinishP = (data) => {
    setLoadingPwd(false);
    console.log(data);
    dispatch(userActions.updatePwd(data));
  };

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const handleChangeImg = (info) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      // Get this url from response in real world.

      if (info && info.file && info.file.response) {
        setImageUrl(config.backendUrlImg + "/" + info.file.response);
      }
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <h1>{t("user.title")}</h1>
      <Form
        {...formItemLayout}
        form={form}
        name="register"
        onFinish={onFinish}
        scrollToFirstError
        initialValues={{
          ["limit"]: "0",
        }}
      >
        <Form.Item
          name="name"
          label={t("user.name")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          name="surname"
          label={t("user.surname")}
          rules={[
            {
              required: true,
              message: t("required"),
            },
          ]}
        >
          <Input />
        </Form.Item>
        {console.log(1243, imageUrl)}
        <Form.Item name="avatar" label={t("user.avatar")}>
          <Upload
            beforeUpload={beforeUpload}
            onChange={handleChangeImg}
            name="avatar"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={config.backendUrl + "/v1/upload"}
          >
            {imageUrl && imageUrl !== "" ? (
              <img src={imageUrl} alt="image" style={{ width: "100%" }} />
            ) : (
              <div>
                {loading ? <LoadingOutlined /> : <PlusOutlined />}
                <div style={{ marginTop: 8 }}>Upload</div>
              </div>
            )}
          </Upload>
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" loading={loading} htmlType="submit">
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
      <h1>{t("user.changePass")}</h1>
      <Form
        {...formItemLayout}
        form={formpwd}
        name="register"
        onFinish={onFinishP}
        scrollToFirstError
        initialValues={{
          ["limit"]: "0",
        }}
      >
        {error && <Alert message={errorMsg} type="error" />}
        <Form.Item
          name="password"
          label={t("login.password")}
          rules={[
            {
              required: true,
              message: t("login.passwordMandatory"),
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("login.password")}
          />
        </Form.Item>
        <Form.Item
          name="passwordrep"
          label={t("login.passwordrep")}
          rules={[
            {
              required: true,
              message: t("login.passwordMandatory"),
            },
          ]}
        >
          <Input
            prefix={<LockOutlined className="site-form-item-icon" />}
            type="password"
            placeholder={t("login.passwordrep")}
          />
        </Form.Item>
        <Form.Item {...tailFormItemLayout}>
          <Button type="primary" htmlType="submit" loading={loadingPwd}>
            {t("save")}
          </Button>
        </Form.Item>
      </Form>
      <div className="showVersion">
        <p>Powered by Clockli</p>
        <p>Version: {packageJson.version}</p>
      </div>
    </div>
  );
}
export default withTranslation()(Profile);
