import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import  LoginPage from "views/auth/login.page";
import  ForgotPage from "views/auth/forgot.page";
import  ChangePage from "views/auth/change.page";
import DashboardPage from "views/dashboard";
import Profile from "views/profile";
import ErrorPage from "views/error";
import Calendar from "views/calendar";
import PermissionPage from "views/permissions"
const dashboardRoutes = [
    {
      path: "/login",
      name: "Login",
 
      component: LoginPage,
      layout: "/auth",
    },
    {
      path: "/change",
      name: "ChangePage",
 
      component: ChangePage,
      layout: "/auth",
    },
    {
      path: "/forgot",
      name: "Forgot",
     
      component: ForgotPage,
      layout: "/auth",
    },
    {
      path: "/dashboard",
      name: "DashboardPage",
     
      component: DashboardPage,
      layout: "/admin",
    },
    {
      path: "/calendar",
      name: "Calendar",
     
      component: Calendar,
      layout: "/admin",
    },
    {
      path: "/vacaciones",
      name: "PermissionPage",
     
      component: PermissionPage,
      layout: "/admin",
    },

    
    {
      path: "/account",
      name: "Profile",
     
      component: Profile,
      layout: "/admin",
    },
    
    {
      path: "/error",
      name: "Error",
     
      component: ErrorPage,
      layout: "/",
    }
    
];

export default dashboardRoutes;