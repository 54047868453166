import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/en-gb";
import "moment/locale/es";
import * as _ from "lodash";
import { es } from "date-fns/locale";
import { DatePickerCalendar } from "react-nice-dates";
import "react-nice-dates/build/style.css";
import { getDay } from "date-fns/esm";
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";
import { Typography } from "antd";
import { timeActions } from "_actions/";
import { TimePicker, Button, Popconfirm } from "antd";
import "./index.scss";
import { v1 as uuidv1 } from "uuid";
import { Checkbox } from "antd";

import { DeleteOutlined } from "@ant-design/icons";

import TimeField from "react-simple-timefield";

const modifiersClassNames = {
  highlight: "-highlight",
};
function Calendar(props) {
  const { t, i18n } = props;
  const { path } = props.match;
  const dispatch = useDispatch();
  const [date, setDate] = useState(new Date(moment().add(-1, "days")));
  const [horas, setHoras] = useState([]);
  const [ausencia, setAusencia] = useState(false);
  const [isHollidays, setIsHollidays] = useState(false);

  const [vacaciones, setVacaciones] = useState(false);
  const [bajaLaboral, setBajaLaboral] = useState(false);
  const storage = localOrSessionStore({ key: variables.REMEMBER });
  const [start, setStart] = useState("00:00");

  const [spinner, setSpinner] = useState(false);
  const [end, setEnd] = useState("23:59");
  const user = JSON.parse(storage.getItem(variables.USER));

  var hoursDayError = useSelector((state) => state.timeReducer.hoursDayError);
  var hoursDay = useSelector((state) => state.timeReducer.hoursDay);

  var addHoursDay = useSelector((state) => state.timeReducer.addHoursDay);

  var hoursDataSaved = useSelector((state) => state.timeReducer.hoursDataSaved);

  var delTimeDayError = useSelector((state) => state.timeReducer.delTimeDayError);
  var delTimeDay = useSelector((state) => state.timeReducer.delTimeDay);

  useEffect(() => {
    if (delTimeDay) {
      dispatch(timeActions.getHoursDate(moment(date).format("YYYY-MM-DD")));
    }
  }, [delTimeDay]);


  useEffect(() => {
    if (addHoursDay) {
      dispatch(timeActions.getHoursDate(moment(date).format("YYYY-MM-DD")));
    }
  }, [addHoursDay]);

  useEffect(() => {
    dispatch(
      timeActions.getHoursDate(
        moment(new Date(moment().add(-1, "days"))).format("YYYY-MM-DD")
      )
    );
  }, []);

  function changeDate(value) {
    setDate(value);
    dispatch(timeActions.getHoursDate(moment(value).format("YYYY-MM-DD")));
  }

  const delHours = (id) => {
    dispatch(timeActions.delHoursDate(id));
  };

  useEffect(() => {
    if (hoursDataSaved) {
      dispatch(timeActions.getHoursDate(moment(date).format("YYYY-MM-DD")));
      setStart("00:00");
      setEnd("23:59");
      setAusencia(false);
      setVacaciones(false);
      console.log("horas guardadas");
    }
  }, [hoursDataSaved]);

  useEffect(() => {
    if (hoursDay) {
      let isHollidays = false;
      hoursDay.map((hora) => {
        if (hora.typepermissionId === 1) {
          isHollidays = true;
        }
      });
      setIsHollidays(isHollidays);
      setHoras(hoursDay);
    }
  }, [hoursDay]);

  function onChangeStart(e, time) {
    setStart(time);
  }
  function onChangeEnd(e, time) {
    setEnd(time);
  }
  const getHoursDiff = (dates) => {
    if (dates.start && dates.end) {
      var m1 = moment(dates.start);
      var m2 = moment(dates.end);
      var m3 = m2.diff(m1, "minutes");
      var m4 = m2.diff(m1, "h");
      var numhours = Math.floor((m3 % 1440) / 60);
      var numminutes = Math.floor((m3 % 1440) % 60);
      return numhours + "h " + numminutes + "m";
    } else {
      return "0h";
    }
  };

  const formatDate = (date) => {
    if (date) return moment(date).format("HH:mm");
    else return "";
  };

  const handleInit = (e) => {
    e.preventDefault();

    let mStart = moment(date).format("YYYY-MM-DD") + " " + start + ":00";
    let mEnd = moment(date).format("YYYY-MM-DD") + " " + end + ":00";
    if (vacaciones) {
      mStart = moment(date).format("YYYY-MM-DD") + " " + "06:00";
      mEnd = moment(date).format("YYYY-MM-DD") + " " + "22:00";
    }
    if (bajaLaboral) {
      mStart = moment(date).format("YYYY-MM-DD") + " " + "06:00";
      mEnd = moment(date).format("YYYY-MM-DD") + " " + "22:00";
    }
    let mBody = {
      type: ausencia ? 3 : vacaciones ? 1 : bajaLaboral ? 4 :  -1,
      start: mStart,
      end: mEnd,
    };
    dispatch(timeActions.addHoursDate(mBody));
  };

  function changeAusencia(value) {
    setAusencia(value);
    setVacaciones(false);
    setBajaLaboral(false);
  }
  function changeVacaciones(value) {
    setAusencia(false);
    setVacaciones(value);
    setBajaLaboral(false);
  }
  function changeBajaLaboral(value){
    setAusencia(false);
    setVacaciones(false);
    setBajaLaboral(value);
  }
  return (
    <div className="dCalendar">
      <DatePickerCalendar
        modifiersClassNames={modifiersClassNames}
        date={date}
        locale={es}
        onDateChange={changeDate}
        maximumDate={new Date(moment().add(-1, "days"))}
      />
      <div className="resume">
        {t("horas.fichajed") + " " + moment(date).format("DD/MM/YYYY")}
      </div>

      {horas &&
        horas.length > 0 &&
        horas.map((hora) => {
          return (
            <div key={uuidv1()}>
              {console.log(hora)}
              {hora.typepermissionId !== 1 ? (
                <div key={uuidv1()}>
                  {hora.start && hora.end && (
                    <>
                      {formatDate(hora.start) + " - " + formatDate(hora.end)}
                      <div className="horasTot">
                        {getHoursDiff(hora)}{" "}
                        {hora.typepermissionId === 3 && <span>A</span>}
                        {hora.typepermissionId === 1 && <span>V</span>}
                        <Popconfirm
                          title={t("horas.borrar")}
                          onConfirm={() => delHours(hora.id)}
                          onCancel={null}
                          okText="Yes"
                          cancelText="No"
                        >
                          <DeleteOutlined style={{ cursor: "pointer" }} />
                        </Popconfirm>
                      </div>
                    </>
                  )}
                </div>
              ) : (
                <div>{t("horas.vacaciones")}</div>
              )}
            </div>
          );
        })}
      {!isHollidays && (
        <>
          <div className="resume">{t("horas.addh")}</div>
          <div>
            <span>{t("horas.inicio")}</span>&nbsp;
            <TimeField
              className="hora"
              value={start}
              placeholder={t("horas.inicio")}
              onChange={onChangeStart}
            />
            &nbsp;&nbsp;&nbsp;<span>{t("horas.fin")}</span>&nbsp;
            <TimeField className="hora" value={end} onChange={onChangeEnd} />
          </div>
          <Checkbox
            checked={ausencia}
            onChange={(e) => changeAusencia(e.target.checked)}
          >
            {t("horas.ausencia")}
          </Checkbox>
          <Checkbox
            checked={vacaciones}
            onChange={(e) => changeVacaciones(e.target.checked)}
          >
            {t("horas.vacaciones")}
          </Checkbox>

          <Checkbox
            checked={bajaLaboral}
            onChange={(e) => changeBajaLaboral(e.target.checked)}
          >
            {t("horas.bajaLaboral")}
          </Checkbox>

          <Button
            disabled={
              (start === "00:00" || end === "23:59") && vacaciones === false && bajaLaboral === false
            }
            style={{ width: 180, marginTop: 1 }}
            size={"small"}
            type="primary"
            key={uuidv1()}
            onClick={(e) => handleInit(e)}
            loading={spinner}
          >
            {t("horas.save")}
          </Button>
        </>
      )}
    </div>
  );
}
export default withTranslation()(Calendar);
