import { all } from "redux-saga/effects";

import watcherCounter from "./counter.saga";
import watcherAuthentication from "./auth.saga";
import watcherUser from "./user.saga";
import sagaTime from "./time.saga";
import watcherPermissions from "./permission.saga"
export default function* rootSaga() {
  yield all([
    watcherCounter(),
    watcherUser(),
    sagaTime(),
    watcherPermissions(),
    watcherAuthentication(),
   
  ]);
}
