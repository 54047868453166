import actionTypes from "_constants/actionTypes";
const initialState = { loading: false, queue: 0, APIactions: 0 };

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.UPDATE_USER_AUTH_DATA:
      return {
        ...state,
        updateUserAuthError: null,
        updateUserAuth: action.payload,
      };
    case actionTypes.UPDATE_USER_AUTH_ERROR:
      return {
        ...state,
        updateUserAuth: null,
        updateUserAuthError: action.payload,
      };
    case actionTypes.UPDATE_PWD_AUTH_DATA:
      return {
        ...state,
        updatePwdAuthError: null,
        updatePwdAuth: action.payload,
      };
    case actionTypes.UPDATE_PWD_AUTH_ERROR:
      return {
        ...state,
        updatePwdAuth: null,
        updatePwdAuthError: action.payload,
      };
      
    case actionTypes.MEINIT_DATA:
      return {
        ...state,
        meInitError: null,
        meInitData: action.payload,
      };
    case actionTypes.MEINIT_ERROR:
      return {
        ...state,
        meInitData: null,
        meInitError: action.payload,
      };
    case actionTypes.MECLEAR:
      return {
        ...state,
        meInitData: null,
        meInitError: null,
      };

    case actionTypes.GET_MEDATA_DATA:
      return {
        ...state,
        meDataError: null,
        meData: action.payload,
      };
    case actionTypes.GET_MEDATA_ERROR:
      return {
        ...state,
        meData: null,
        meDataError: action.payload,
      };

    case actionTypes.GET_RANKINGDATA_DATA:
      return {
        ...state,
        rankingDataError: null,
        rankingData: action.payload,
      };
    case actionTypes.GET_RANKINGDATA_ERROR:
      return {
        ...state,
        rankingData: null,
        rankingDataError: action.payload,
      };

    case actionTypes.GET_LOGROSDATA_DATA:
      return {
        ...state,
        logrosDataError: null,
        logrosData: action.payload,
      };
    case actionTypes.GET_LOGROSDATA_ERROR:
      return {
        ...state,
        logrosData: null,
        logrosDataError: action.payload,
      };

    case actionTypes.GET_PUNTOSDATA_DATA:
      return {
        ...state,
        puntosDataError: null,
        puntosData: action.payload,
      };
    case actionTypes.GET_PUNTOSDATA_ERROR:
      return {
        ...state,
        puntosData: null,
        puntosDataError: action.payload,
      };

    case actionTypes.GET_NIVELES_DATA:
      return {
        ...state,
        nivelesDataError: null,
        nivelesData: action.payload,
      };
    case actionTypes.GET_NIVELES_ERROR:
      return {
        ...state,
        nivelesData: null,
        nivelesDataError: action.payload,
      };


      case actionTypes.GET_FECHAANIMO_DATA:
        return {
          ...state,
          fechaDataError: null,
          fechaData: action.payload,
        };
      case actionTypes.GET_FECHAANIMO_ERROR:
        return {
          ...state,
          fechaData: null,
          fechaDataError: action.payload,
        };
  

      case actionTypes.SAVE_ANIMO_DATA:
        return {
          ...state,
          saveDataError: null,
          saveData: action.payload,
        };
      case actionTypes.SAVE_ANIMO_ERROR:
        return {
          ...state,
          saveData: null,
          saveDataError: action.payload,
        };
  
    default:
      return {
        ...state,
      };
  }
};
