import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox } from "antd";
import { Row, Col, Divider, Alert, Rate } from "antd";
import { useTranslation } from "react-i18next";
import { v1 as uuidv1 } from "uuid";
import moment from "moment";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/img/logo2.png";
import { timeActions } from "_actions/";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";
import { Typography } from "antd";
import "./index.scss";

import yate from "assets/img/yate.png";

const { Title, Paragraph, Text } = Typography;
const storage = localOrSessionStore({ key: variables.REMEMBER });

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};
function DashboardPage(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [isHollidays, setIsHollidays] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [spinner, setSpinner] = useState(false);
  const [difference, setDifference] = useState("");
  const [listHours, setListHours] = useState([]);
  const [isStart, setIsStart] = useState(false);
  const [dateStarted, setDateStarted] = useState(null);
  const [dayEnded, setDayEnded] = useState(false);
  const [enableEnd, setEnableEnd] = useState(false);
  const [showMood, setShowMood] = useState(false);
  const [showHasta, setShowHasta] = useState(false);
  const [animo, setAnimo] = useState(0);
  const user = JSON.parse(storage.getItem(variables.USER));

  var lastUser = useSelector((state) => state.timeReducer.lastUser);
  var lastUserError = useSelector((state) => state.timeReducer.lastUserError);

  var initTimeUser = useSelector((state) => state.timeReducer.initTimeUser);
  var initTimeUserError = useSelector(
    (state) => state.timeReducer.initTimeUserError
  );

  var endTimeUser = useSelector((state) => state.timeReducer.endTimeUser);
  var endDayUser = useSelector((state) => state.timeReducer.endDayUser);
  var endTimeUserError = useSelector(
    (state) => state.timeReducer.endTimeUserError
  );

  useEffect(() => {
    if (endTimeUser || endDayUser) {
      setSpinner(false);
      getHours();
    }
  }, [endTimeUserError, endTimeUser, endDayUser]);
  useEffect(() => {
    if (initTimeUser) {
      setSpinner(false);
      getHours();
    }
  }, [initTimeUser, initTimeUserError]);

  useEffect(() => {
    if (lastUser && lastUser.length > 0) {
      lastUser.map((item) => {
        console.log(15, item);
        if (item.typepermissionId === 1) {
          setIsHollidays(true);
        } else {
          setIsHollidays(false);
        }
        setDateStarted(item);
        setDayEnded(item.dayEnded);
        if (item.start && item.end == null) {
          setIsStart(true);

          printDiff(item);
          const interval = setInterval(() => {
            printDiff(item);
          }, 60000);
          return () => clearInterval(interval);
        }
      });

      setListHours(lastUser);
    }
  }, [lastUser, lastUserError]);

  useEffect(() => {
    getHours();
  }, []);

  const getHours = () => {
    dispatch(timeActions.getLast());
  };

  const formatDate = (date) => {
    return moment(date).format("HH:mm");
  };

  const getTotalHours = () => {
    var totalMinutes = 0;
    listHours.map((hour) => {
      console.log(444, hour);

      if (hour.end) {
        var m1 = moment(hour.start);
        var m2 = moment(hour.end);
        var m3 = m2.diff(m1, "minutes");
        totalMinutes += m3;
      }
    });

    var h = (totalMinutes / 60) | 0;
    var m = totalMinutes % 60 | 0;

    return h + "h  " + m + " m";
  };

  const getHoursDiff = (dates) => {
    var m1 = moment(dates.start);
    var m2 = moment(dates.end);
    var m3 = m2.diff(m1, "minutes");
    var m4 = m2.diff(m1, "h");
    var numhours = Math.floor((m3 % 1440) / 60);
    var numminutes = Math.floor((m3 % 1440) % 60);
    return numhours + "h " + numminutes + "m";
  };

  const printDiff = (item) => {
    let mDate = dateStarted;
    if (dateStarted === null) {
      mDate = item;
    }
    if (mDate && mDate.start) {
      const date = mDate.start;
      var m1 = moment(date);
      var m2 = moment();
      var m3 = m2.diff(m1, "minutes");
      var m4 = m2.diff(m1, "h");
      var numhours = Math.floor((m3 % 1440) / 60);
      var numminutes = Math.floor((m3 % 1440) % 60);
      setDifference(numhours + "h " + numminutes + "m");
    }
  };

  const handleInit = () => {
    setEnableEnd(false);
    setSpinner(true);
    dispatch(timeActions.initTime());
  };

  const handleEnd = () => {
    setEnableEnd(true);
    dispatch(timeActions.endTime(dateStarted));
  };

  const handleEndDay = () => {
    setShowMood(true);
    dispatch(timeActions.endDay());
  };

  const handleChange = (value) => {
    setAnimo(value);
  };
  const sendAnimo = () => {
    dispatch(timeActions.updateMood(animo));
    setShowMood(false);
    setShowHasta(true);
    // dispatch(userActions.saveAnimo(animo));
  };
  const noSend = () => {
    setShowMood(false);
    setShowHasta(true);
  };
  return (
    <>
      <Title level={4}>{moment().format("DD/MM/YYYY")}</Title>
      {isHollidays ? (
        <>
        
        <img src={yate} className="hollidays" />
        <Title level={3}>{t("horas.invacas")}</Title>
        </>
      ) : (
        <>
          {listHours &&
            listHours.map((date) => {
              return (
                date.end && (
                  <div key={uuidv1()}>
                    <div key={uuidv1()}>
                      {formatDate(date.start) + " - " + formatDate(date.end)}
                    </div>
                    <div>{getHoursDiff(date)}</div>
                  </div>
                )
              );
            })}

          {dateStarted && dateStarted.end === null && (
            <div key={uuidv1()}>
              <div style={{ marginTop: 15 }}>
                {t("horas.inicio")}: {formatDate(dateStarted.start)} (
                {difference})
              </div>
            </div>
          )}

          {listHours && listHours.length > 0 && (
            <div key={uuidv1()}>
              <Title level={5} key={uuidv1()}>
                {t("horas.total")}: {getTotalHours()}
              </Title>
            </div>
          )}

          {!dayEnded && (!dateStarted || dateStarted === null) && (
            <div key={uuidv1()}>
              <Button
                style={{ width: 180, marginTop: 15 }}
                size={"large"}
                type="primary"
                key={uuidv1()}
                onClick={handleInit}
                loading={spinner}
              >
                {t("horas.start")}
              </Button>
            </div>
          )}

          {dayEnded && (
            <Title level={3} key={uuidv1()}>
              {t("horas.vuelveman")}
            </Title>
          )}

          {!dayEnded && dateStarted && dateStarted.end !== null && (
            <>
              <div key={uuidv1()}>
                <Button
                  style={{ width: 180, marginTop: 15 }}
                  size={"large"}
                  type="primary"
                  key={uuidv1()}
                  onClick={handleInit}
                  loading={spinner}
                >
                  {t("horas.continue")}
                </Button>
              </div>
            </>
          )}
          {!dayEnded && dateStarted && dateStarted.end === null && (
            <div key={uuidv1()}>
              <Button
                style={{ width: 180, marginTop: 15 }}
                size={"large"}
                key={uuidv1()}
                onClick={handleEnd}
                loading={spinner}
                type="primary"
              >
                {t("horas.end")}
              </Button>
            </div>
          )}
          {!dayEnded && listHours && listHours.length > 0 && (
            <div key={uuidv1()}>
              <Button
                disabled={!enableEnd}
                style={{ width: 180, marginTop: 15 }}
                size={"large"}
                key={uuidv1()}
                onClick={handleEndDay}
                loading={spinner}
              >
                {t("horas.day")}
              </Button>
            </div>
          )}

          {showMood && listHours && listHours.length > 0 && (
            <div className="ratingC">
              <Title level={5} key={uuidv1()}>
                {t("horas.sentido")}
              </Title>
              <Rate
                onChange={handleChange}
                character={({ index }) => customIcons[index + 1]}
              />
              <div>
                {animo > 0 && (
                  <div key={uuidv1()}>
                    <Button
                      onClick={sendAnimo}
                      style={{ width: 180, marginTop: 15 }}
                      size={"large"}
                      type="primary"
                    >
                      {t("enviar")}
                    </Button>
                  </div>
                )}
                <div key={uuidv1()}>
                  <Button
                    onClick={noSend}
                    style={{ width: 180, marginTop: 15 }}
                    size={"large"}
                  >
                    {t("cancel")}
                  </Button>
                </div>
              </div>
            </div>
          )}

          {showHasta && (
            <Title level={4} key={uuidv1()}>
              {t("horas.hasta")}
            </Title>
          )}
        </>
      )}
    </>
  );
}
export default withTranslation()(DashboardPage);
