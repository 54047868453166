import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import App from "./App";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";
import store from "./store.js";
import { history } from "_helpers/history";
import { PrivateRoute } from "_helpers/privateRoute";
import Admin from 'layouts/Admin';
import Auth from 'layouts/Auth';
import variables from "_constants/variables";
import localOrSessionStore from "_helpers/localOrSessionStore";
import ErrorPage from "views/error";
import { Provider } from "react-redux";
import "./i18n";

//import "antd/dist/antd.css";
import 'antd-mobile/dist/antd-mobile.css';

import "./index.less";
import "assets/sass/normal.scss";


const storage = localOrSessionStore({ key: variables.REMEMBER });

const Loader = () => {
  return <div>loading...</div>;
};

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback={<Loader />}>
      <Router history={history}>
        <Switch>
          <Route path="/auth" component={Auth} />
          <PrivateRoute path="/admin" component={Admin} />
          {
            storage.getItem(variables.TOKEN) ?   <Redirect from="/" to="/admin/dashboard" /> :  <Redirect from="/" to="/auth/login" />
          }
         </Switch>
      </Router>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
