import { takeEvery, call, put, select } from "redux-saga/effects";
import actionTypes from "_constants/actionTypes";
import { service } from "../_services/service";
import { history } from "_helpers/history";
import variables from "_constants/variables";
import config from "_config/config";
import localOrSessionStore from "_helpers/localOrSessionStore";
export default function* watcherUser() {
  //yield takeEvery(actionTypes.MEDATA, me);
  //yield takeEvery(actionTypes.MEPASSWORD, password);
  yield takeEvery(actionTypes.MEINIT, init);
  yield takeEvery(actionTypes.GET_MEDATA, getMe);

  yield takeEvery(actionTypes.GET_LOGROSDATA, logros);
  yield takeEvery(actionTypes.GET_PUNTOSDATA, puntos);
  yield takeEvery(actionTypes.GET_RANKINGDATA, ranking);
  yield takeEvery(actionTypes.GET_NIVELES, niveles);
  yield takeEvery(actionTypes.GET_FECHAANIMO, fechaAnimo);

  yield takeEvery(actionTypes.SAVE_ANIMO, saveAnimo);

  yield takeEvery(actionTypes.UPDATE_USER_AUTH, updateUser);
  yield takeEvery(actionTypes.UPDATE_PWD_AUTH, updatePwd);
}


function* updatePwd({payload}) {
  yield put({ type: actionTypes.START_REQUEST });
  
  try {
    let apiEndpoint = "/v1/me/password";
    console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.UPDATE_PWD_AUTH_DATA,
        payload: res.data,
      });
    } else {
      console.log(res);
      yield put({
        type: actionTypes.UPDATE_PWD_AUTH_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_PWD_AUTH_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* updateUser({payload}) {
  yield put({ type: actionTypes.START_REQUEST });
  
  try {
    let apiEndpoint = "/v1/me";
    console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.UPDATE_USER_AUTH_DATA,
        payload: res.data,
      });
    } else {
      console.log(res);
      yield put({
        type: actionTypes.UPDATE_USER_AUTH_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.UPDATE_USER_AUTH_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* saveAnimo({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/animo";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.postToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      
      yield put({
        type: actionTypes.SAVE_ANIMO_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.SAVE_ANIMO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.SAVE_ANIMO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* fechaAnimo({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/animo";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_FECHAANIMO_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_FECHAANIMO_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_FECHAANIMO_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}
function* niveles({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/niveles";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_NIVELES_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_NIVELES_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_NIVELES_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* ranking({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/ranking";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_RANKINGDATA_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_RANKINGDATA_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_RANKINGDATA_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* puntos({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/puntos";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_PUNTOSDATA_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_PUNTOSDATA_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_PUNTOSDATA_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


function* logros({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/logros";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_LOGROSDATA_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_LOGROSDATA_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_LOGROSDATA_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}



function* getMe({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/me";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.getToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.GET_MEDATA_DATA,
        payload: res.data,
      });

     
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.GET_MEDATA_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.GET_MEDATA_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}

function* init({ payload }) {
  yield put({ type: actionTypes.START_REQUEST });

  try {
    let apiEndpoint = "/v1/me/init";
    // console.log(payload);
    const res = yield call(
      () =>
        new Promise((resolve, reject) => {
          service.putToken(apiEndpoint, payload).then((response) => {
            resolve(response);
          });
        })
    );
    if (res.status === 200) {
      yield put({
        type: actionTypes.MEINIT_DATA,
        payload: res.data,
      });

      yield put({
        type: actionTypes.MECLEAR,
        payload: res.data,
      });
    } else {
      // console.log(res);
      yield put({
        type: actionTypes.MEINIT_ERROR,
        payload: res.data,
      });
    }
  } catch (e) {
    yield put({ type: actionTypes.MEINIT_ERROR, payload: e });
  }
  yield put({ type: actionTypes.END_REQUEST });
}


