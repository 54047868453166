import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { withTranslation } from "react-i18next";
import { Form, Input, Button, Checkbox } from "antd";
import { Row, Col, Divider, Alert } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import logo from "assets/img/logo2.png";
import {authActions} from "_actions/";

import pixel from "assets/img/pixel.png";
import variables from "_constants/variables";
const layout = {
  labelCol: {
    span: 8,
  },
  wrapperCol: {
    span: 16,
  },
};
const tailLayout = {
  wrapperCol: {
    offset: 8,
    span: 16,
  },
};
function ChangePage(props) {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  
  var changePass = useSelector((state) => state.authReducer.changePass);
  var changePassUserError = useSelector(
    (state) => state.authReducer.changePassUserError
  );


  useEffect(() => {
    console.log(99, changePassUserError);
    setLoading(false);
    if (changePass) {
      setError(false);
    }
    if (changePassUserError) {
      setError(true);
      setErrorMsg(t(changePassUserError.codeMsg));
    }
  }, [changePass, changePassUserError]);

  const onFinish = (values) => {
    console.log("Received values of form: ", values);
    setLoading(true);
    dispatch(authActions.change( sessionStorage.getItem(variables.EMAIL),values.password, values.passwordrep));
  };



   return (
    <>
      <Row justify="space-around" align="middle" >
      <Col xl={7} lg={7} md={10} sm={18} xs={20} className="form-padding">
          <div style={{textAlign: "center"}}><img src={logo} style={{height: 65}}  /></div>
          
          <Divider orientation="left"></Divider>
          <Form
            name="normal_login"
            className="login-form"
            initialValues={{
              remember: true,
            }}
            onFinish={onFinish}
          >
            <h4 className="gwh1" style={{textAlign: "center"}}>{t("login.change")}</h4>
            <Form.Item
              name="password"
              rules={[
                {
                  required: true,
                  message: t("login.passwordMandatory"),
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("login.passwordnew")}
              />
            </Form.Item>
            <Form.Item
              name="passwordrep"
              rules={[
                {
                  required: true,
                  message: t("login.passwordMandatory"),
                },
              ]}
            >
              <Input
                prefix={<LockOutlined className="site-form-item-icon" />}
                type="password"
                placeholder={t("login.passwordrep")}
              />
            </Form.Item>

            <Form.Item>
              <Button
                type="primary"
                loading={loading}
                htmlType="submit"
                className="login-form-button"
              >
                 {t("change")}
              </Button>
              {error && <Alert message={errorMsg} type="error" />}
            </Form.Item>
          </Form>
          Powered by:<br />
          <img style={{width: 100}} src={pixel}  />
        </Col>
      </Row>
    </>
  );
}
export default withTranslation()(ChangePage);
